import { ContentfulAsset } from '../types';
import qs from 'query-string';

export function makeWebpUrl(imgUrl: string, quality = 80) {
  const { url, query } = qs.parseUrl(imgUrl);
  delete query.fl;
  query.fm = 'webp';
  query.q = `${quality}`;
  return `${url}?${qs.stringify(query)}`;
}

export function makeJpgUrl(imgUrl: string, fl?: string, quality?: number) {
  const { url, query } = qs.parseUrl(imgUrl);
  query.fm = 'jpg';
  if (fl) query.fl = `${fl}`;
  if (quality) query.q = `${quality}`;
  return `${url}?${qs.stringify(query)}`;
}

export function makeImageSizeUrl(
  imgUrl: string,
  width?: number,
  height?: number,
  fit?: string,
  focus?: string,
) {
  const { url, query } = qs.parseUrl(imgUrl);
  if (width !== undefined && !isNaN(width)) query.w = `${width}`;
  if (height !== undefined && !isNaN(height)) query.h = `${height}`;
  if (fit) query.fit = `${fit}`;
  if (focus) query.f = `${focus}`;
  return `${url}?${qs.stringify(query)}`;
}

export function makeContentfulAsset({
  url,
  contentType,
  width,
  height,
  size = -1,
  description = '',
}: {
  url: string;
  contentType: string;
  width?: number;
  height?: number;
  size?: number;
  description?: string;
}): ContentfulAsset {
  return {
    file: {
      contentType,
      url,
      details: {
        size,
        image: {
          width,
          height,
        },
      },
    },
    description,
    contentType,
  };
}
