import React from 'react';
import styled from 'styled-components';
import { ModuleType } from '../types';

import {
  cssColorValue,
  screenXxsMax,
  screenXsMin,
  screenSmMin,
  screenSmMax,
  screenMdMin,
  Text,
  cssSpacing,
} from '@spotify-internal/encore-web';
import { articleMarkdown, defaultMarkdown } from '../../Markdown';
import { ShareButtonsComponent } from '../../ui/ShareButtons';
import { ImageAspectBox } from '../../ui';
import { useT } from '@spotify-internal/i18n-core';

const maxWidthOfImage = '800px';
const maxWidthOfText = '664px';

const Article = styled.div`
  background-color: ${cssColorValue('backgroundBase')};
  margin-bottom: ${cssSpacing('looser-5')};
  @media (max-width: ${screenXxsMax}) {
    margin-left: ${cssSpacing('base')};
    margin-right: ${cssSpacing('base')};
  }

  @media (min-width: ${screenXsMin}) and (max-width: ${screenSmMax}) {
    margin-left: 52px;
    margin-right: 52px;
  }

  @media (min-width: ${screenSmMin}) and (max-width: ${screenSmMax}) {
    width: ${maxWidthOfText};
    margin: 0 auto ${cssSpacing('looser-5')};
  }

  @media (min-width: ${screenMdMin}) {
    width: ${maxWidthOfImage};
    margin: 0 auto ${cssSpacing('looser-5')};
  }
`;

const IntroductionWrapper = styled.div`
  padding-bottom: 0;

  @media (min-width: ${screenMdMin}) {
    max-width: 664px;
    margin: auto;
  }

  @media (max-width: ${screenXxsMax}) {
    margin-bottom: ${cssSpacing('looser')};
  }

  @media (min-width: ${screenXsMin}) {
    margin-bottom: ${cssSpacing('tighter-4')};
  }
`;

const Introduction = styled(Text).attrs({
  variant: 'titleMedium',
})`
  @media (max-width: ${screenXxsMax}) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }

  @media (min-width: ${screenXsMin}) {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  a {
    color: ${cssColorValue('textBase')};
    text-decoration: underline;
    &:hover {
      color: ${cssColorValue('textBrightAccent')};
    }
  }
`;

const BodyWrapper = styled.div``;

const Body = styled.div<{ props: any }>`
  @media (min-width: ${screenMdMin}) {
    & > * {
      width: ${maxWidthOfText};
      margin: auto;
    }

    p.article-picture,
    div {
      width: ${maxWidthOfImage};
    }
  }

  iframe {
    display: block;
    width: 100%;
    margin: auto;
    margin-bottom: ${cssSpacing('tighter-4')};
  }

  ${ImageAspectBox} {
    width: 100%;
    aspect-ratio: 16 / 9;
    margin: auto;

    iframe {
      height: 100%;
      padding-bottom: 0;
    }
  }

  img,
  div > iframe {
    width: 100%;
    margin-bottom: ${cssSpacing('looser-4')};
  }

  figcaption {
    text-align: left;
  }

  a {
    text-decoration: underline;
    color: ${cssColorValue('textBase')};
    &:hover,
    &:focus:not(:disabled),
    &:active:not(:disabled),
    &.highlight {
      color: ${cssColorValue('textBrightAccent')};
      -webkit-tap-highlight-color: ${cssColorValue('textBrightAccent')};
    }
  }

  ul,
  ol {
    padding-left: ${cssSpacing('looser')};
  }

  ol > li,
  ul > li {
    margin-bottom: ${cssSpacing('base')};
  }

  p {
    margin-top: 0;
  }

  p,
  ul,
  ol {
    padding-block-end: 0;
    margin-bottom: ${cssSpacing('looser')};
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  h3 {
    margin-bottom: ${cssSpacing('base')};
  }

  blockquote {
    border: 0;
    padding: 0;
    margin: ${cssSpacing('looser')} auto;

    p {
      color: ${cssColorValue('textBrightAccent')};
      font-weight: 450;
      letter-spacing: -0.02em;
    }
  }

  @media (max-width: ${screenXxsMax}) {
    :not(iframe) {
      margin-bottom: ${cssSpacing('looser-4')};
    }

    p + :not(p, blockquote, ul, ol, iframe),
    p + .article-picture {
      margin-top: ${cssSpacing('looser-4')};
    }

    h2 {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    h3 {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    p,
    ol > li,
    ul > li {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    blockquote p {
      font-size: 1.625rem;
      line-height: 2.25rem;
    }
  }

  @media (min-width: ${screenXsMin}) {
    :not(iframe) {
      margin-bottom: ${cssSpacing('looser-3')};
    }

    p + :not(p, blockquote, ul, ol, iframe),
    p + .article-picture {
      margin-top: ${cssSpacing('looser-4')};
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.25rem;
    }

    p,
    ol > li,
    ul > li {
      font-size: 1.25rem;
      line-height: ${cssSpacing('looser-2')};
    }

    blockquote p {
      font-size: 1.75rem;
      line-height: ${cssSpacing('looser-4')};
    }
  }
`;

const BottomSection = styled.div`
  @media (min-width: ${screenMdMin}) {
    width: ${maxWidthOfText};
    margin: auto;
  }

  display: flex;
  flex-direction: column;
  border-top: 1px solid ${cssColorValue('decorativeSubdued')};
  padding-top: ${cssSpacing('tighter')};

  p {
    margin-bottom: ${cssSpacing('looser-4')};
  }
`;

const ShareButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-top: -2px;
    padding-bottom: 0;
  }
`;

export type ArticleModuleType = ModuleType & {
  introduction?: string;
  body?: string;
  initialDropcap?: boolean;
};

export const ArticleModule: React.FC<ArticleModuleType> = ({
  introduction = '',
  body = '',
  initialDropcap = false,
}) => {
  const t = useT();
  const markDown = articleMarkdown(body, initialDropcap);

  return (
    <Article>
      <IntroductionWrapper>
        <Introduction
          dangerouslySetInnerHTML={{
            __html: defaultMarkdown(introduction),
          }}
        />
      </IntroductionWrapper>
      <BodyWrapper>
        <Body
          dangerouslySetInnerHTML={{
            __html: markDown,
          }}
        />
      </BodyWrapper>
      <BottomSection>
        <Text as="p" variant="bodyMedium">
          {t(
            'FRODOR_e7365a',
            'Spotify for Artists helps you to develop the fanbase you need to reach your goals.',
            '',
          )}
        </Text>
        <ShareButtonContainer>
          <Text variant="bodyMediumBold" paddingBottom="base">
            {t(
              'FRODOR_f92bd3',
              'Share this story',
              'Share refers to sharing on social media',
            )}
          </Text>
          <ShareButtonsComponent />
        </ShareButtonContainer>
      </BottomSection>
    </Article>
  );
};
