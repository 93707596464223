// ignore-string-externalization

import React from 'react';
import styled, { CSSProperties } from 'styled-components';
import memoize from 'lodash/memoize';
import qs from 'query-string';

import { hexToRgba } from '../../../../features/src/color-conversion';
import {
  black,
  screenXsMax,
  white,
  gray50,
  cssSpacing,
  Text,
} from '@spotify-internal/encore-web';
import { formatText } from '../../utils';
import { AssetDebugFrame, AssetConstraints } from './AssetDebugFrame';
import { makeImageSizeUrl, makeWebpUrl, makeJpgUrl } from './utils';
import { EMPTY_IMAGE } from './constants';
import { useSearchParams } from 'next/navigation';
import { ContentfulAsset } from '../types';

// [screen width, image width]
type MediaSize = [number, number];

type ImageProps = {
  asset: ContentfulAsset;
  caption?: string;
  imageStyle?: {};
  captionStyle?: {};
  imageComponent?: React.FC;
  fit?: string;
  focus?: string;
  format?: string[]; // fm/fl params, e.g. ['jpg', 'progressive']
  assetWidth?: number;
  assetHeight?: number;
  lazyLoad?: boolean;
  sizes?: MediaSize[]; // [ [media query width, image width] ]
  placeholder?: boolean | string;
  children?: React.ReactNode; // additional content under the picture element
  allowVideo?: boolean;
  videoOptions?: { [key: string]: any };
  debug?: boolean;
  constraints?: AssetConstraints;
};

const StyledImage = styled.img``;

const StyledImageWithPlaceholder = styled(StyledImage)`
  &.lazyloading {
    filter: blur(8px);
  }
  &.lazyloaded {
    filter: none;
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`;

const CaptionText = styled(Text).attrs({
  forwardedAs: 'p',
  variant: 'marginal',
})`
  position: absolute;
  bottom: ${cssSpacing('base')};
  right: ${cssSpacing('base')};
  text-align: right;
  background-color: ${hexToRgba(black, 0.5)};
  width: fit-content;
  padding: 0 5px 0 5px;
  color: ${white};
  @media (max-width: ${screenXsMax}) {
    bottom: ${cssSpacing('tighter-2')};
    right: ${cssSpacing('tighter-2')};
  }
`;

export const lightCaptionStyle: CSSProperties = {
  position: 'relative',
  color: gray50,
  background: white,
  textAlign: 'center',
};

export const ContentfulImage: React.FC<ImageProps> = (props: ImageProps) => {
  const {
    asset,
    caption,
    imageStyle = {},
    captionStyle = {},
    imageComponent,
    fit,
    focus,
    format,
    lazyLoad = true,
    sizes = [
      [480, 480],
      [768, 768],
      [1280, 1280],
      [1600, 1600],
    ],
    children,
    allowVideo = true,
    videoOptions = {
      autoPlay: true,
      playsInline: true,
      loop: true,
      muted: true,
    },
    constraints,
  } = props;

  const searchParams = useSearchParams();
  const debug = props.debug || 'debug_assets' in (searchParams || {});

  const pictureRef = React.useRef<HTMLPictureElement>(null);

  if (allowVideo && asset?.file?.contentType?.startsWith('video/')) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video {...videoOptions} title={asset.description}>
        <source src={asset.file.url} type={asset.file.contentType} />
      </video>
    );
  }

  if (
    // !asset?.file?.details?.image?.width ||
    !asset?.file?.contentType?.startsWith('image/')
  ) {
    // eslint-disable-next-line no-console
    console.error('Invalid asset', asset?.title, asset?.file?.contentType);
    return <></>;
  }

  const contentType = asset.file.contentType;
  const { url, query } = qs.parseUrl(asset.file.url);
  const assetWidth = props.assetWidth || asset.file.details.image.width;
  const assetHeight = props.assetHeight || asset.file.details.image.height;
  const description = asset.description;

  if (fit) {
    query.fit = fit;
  }

  if (focus) {
    query.f = focus;
  }

  if (format) {
    query.fm = format[0];
    if (format.length > 1) {
      query.fl = format[1];
    }
  }

  // only set width and height of the image in the query if they were passed in as explicit
  // parameters, otherwise allow contentful to return the natural values
  if (props.assetWidth) {
    query.w = `${assetWidth}`;
  }
  if (props.assetHeight) {
    query.h = `${assetHeight}`;
  }

  const baseImageUrl = `${url}?${qs.stringify(query)}`;
  const baseWebpImageUrl = makeWebpUrl(baseImageUrl);
  const size = memoize(
    (w: number | undefined) => makeImageSizeUrl(baseImageUrl, w, undefined),
    w => w,
  );
  const sizeWebp = memoize(
    (w: number | undefined) => makeImageSizeUrl(baseWebpImageUrl, w, undefined),
    w => w,
  );

  let { placeholder = true } = props;
  if (placeholder === true) {
    placeholder = makeImageSizeUrl(
      makeJpgUrl(baseImageUrl, undefined, 0),
      12,
      10,
    );
  }

  const ImageComponent =
    imageComponent || (placeholder ? StyledImageWithPlaceholder : StyledImage);

  const fallbackSrcSet = [...sizes]
    .map(([q, w]: MediaSize) => `${size(w)} ${q}w`)
    .join(', ');

  const getMediaQuery = (ms: MediaSize) => {
    const i = sizes.indexOf(ms) - 1;
    const min = i < 0 ? '' : `(min-width: ${sizes[i][0] + 1}px) and`;
    return `${min} (max-width: ${ms[1]}px)`.trim();
  };

  const srcSetProp = lazyLoad ? 'data-srcset' : 'srcSet';

  const makeSource = (mediaQuery: string, ctype: string, srcSet: string) => (
    <source media={mediaQuery} type={ctype} {...{ [srcSetProp]: srcSet }} />
  );

  const pictureContent = (
    <>
      {/* jpg/webp sources for all image sizes, including 2x for retina */}
      {assetWidth &&
        sizes.map((ms: MediaSize) => (
          <React.Fragment key={`${[ms[0], ms[1]].join('x')}`}>
            {makeSource(
              getMediaQuery(ms),
              'image/webp',
              `${sizeWebp(ms[1])} 1x, ${sizeWebp(
                assetWidth && Math.min(assetWidth, ms[1] * 2),
              )} 2x`,
            )}
            {makeSource(
              getMediaQuery(ms),
              contentType,
              `${size(ms[1])} 1x, ${size(Math.min(assetWidth, ms[1] * 2))} 2x`,
            )}
          </React.Fragment>
        ))}

      {/* fallback image, also defines style & alt elements */}
      <ImageComponent
        {...{ [srcSetProp]: fallbackSrcSet }}
        srcSet={lazyLoad ? placeholder || EMPTY_IMAGE : ''}
        data-sizes="auto"
        alt={description}
        style={{ display: 'block', ...imageStyle }}
        className={lazyLoad ? 'lazyload' : ''}
      />
      {caption && (
        <CaptionText
          style={captionStyle}
          dangerouslySetInnerHTML={{ __html: formatText(caption) }}
        />
      )}
    </>
  );

  const picture = (
    <picture ref={pictureRef}>
      {pictureContent}
      {children}
    </picture>
  );

  const defaultConstraints = allowVideo ? { video: { required: false } } : {};

  return debug ? (
    <AssetDebugFrame
      asset={asset}
      constraints={{
        ...defaultConstraints,
        ...constraints,
      }}
      pictureRef={pictureRef}
    >
      {picture}
    </AssetDebugFrame>
  ) : (
    <>{picture}</>
  );
};
