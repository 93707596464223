// ignore-string-externalization
//
import React, { useContext } from 'react';
import {
  AccordionModule,
  GroupTestimonialModule,
  ChecklistModule,
  HeroNarrativeCarouselModule,
  HeroNarrativeTextModule,
  HeroNarrativeQuoteModule,
  MiniCarouselNarrativeModule,
  SingleTestimonialModule,
  AnimationContext,
  useAnimations,
  customThreshold,
  GraphicTransitionModule,
  GraphicTransitionContext,
  TwoUpModule,
  FullWidthVideoModule,
  FullWidthCarouselModule,
  PageBreakerModule,
  VideoModule,
  HeroBodyModule,
  HeroBodyCarousel,
  HeaderModule,
} from '@spotify-internal/frodor-modules';
import { RelatedLinksModule } from './RelatedLinksModule';
import { FAQLinksModule } from './FAQLinksModule';
import { MiniCarouselNoHeroModule } from './MiniCarouselNoHeroModule';
import { ModuleCarousel } from './ModuleCarousel/ModuleCarousel';
import { ThreeUpModule } from './ThreeUpFeaturedContentModule';
import { JumpLinkHeroModule } from './JumpLinkHeroModule';
import { EditorialGridModule } from './EditorialGridModule';
import { LinkGridModule } from './LinkGridModule';
import { RelatedStoriesListModule } from './RelatedStoriesListModule';
import { ArticleHeaderModule } from './ArticleHeaderModule';
import { ArticleModule } from './ArticleModule';
import { FeaturedContentModule } from './FeaturedContentModule';
import { ModuleType } from './types';
import { TrackVisibility } from '../../utils/Visibility/TrackVisibility';

export const Module = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  ModuleType
>((props, ref) => {
  const {
    contentType,
    shouldAnimate = true,
    title = '',
    onShowSlide,
    modulePageIndex,
  } = props;
  const ModuleComponent = MODULE_COMPONENTS[contentType];
  const { animationContext, animationRef } = useAnimations(
    contentType,
    shouldAnimate,
    customThreshold(contentType),
  );

  const { getTransitionColors, showCarouselSlide } = useContext(
    GraphicTransitionContext,
  );

  const showSlideWithContext = (slide: ModuleType, idx: number) => {
    showCarouselSlide && showCarouselSlide(props, slide);
    onShowSlide && onShowSlide(slide, idx);
  };
  const isTransitionModule = contentType === 'graphicTransitionModule';
  // include graphic transition context / colors
  const modProps = {
    ...props,
    onShowSlide: showSlideWithContext,
    transitionColors:
      isTransitionModule &&
      modulePageIndex &&
      getTransitionColors &&
      getTransitionColors(modulePageIndex),
  };
  if (ModuleComponent) {
    const longModuleThresholds: { [key: string]: number } = {
      linkGrid: 0.4,
      editorialGridModule: 0.4,
      accordionModule: 0.4,
      articleModule: 0.01,
    };
    return (
      <TrackVisibility
        threshold={longModuleThresholds[contentType] || 0.6}
        eventCategory={`Frodor - module-${contentType}`}
        eventLabel={title}
      >
        <AnimationContext.Provider value={animationContext}>
          <div
            id={`${props.id}-${props.contentType}`}
            style={{ scrollMarginTop: '76px' }}
          >
            <ModuleComponent {...modProps} ref={mergeRefs(ref, animationRef)} />
          </div>
        </AnimationContext.Provider>
      </TrackVisibility>
    );
  }
  return <>Module type "{contentType}" not found</>;
});

const Carousel = React.forwardRef((props: any, ref: any) => (
  <ModuleCarousel {...props} ref={ref} Module={Module} />
));

const MODULE_COMPONENTS: { [key: string]: React.FC<any> | undefined } = {
  articleModule: ArticleModule,
  accordionModule: AccordionModule,
  checklistModule: ChecklistModule,
  faqLinksModule: FAQLinksModule,
  pageBreakerModule: PageBreakerModule,
  editorialGridModule: EditorialGridModule,
  jumpLinkHeroModule: JumpLinkHeroModule,
  graphicTransitionModule: GraphicTransitionModule,
  singleFeatureModule: MiniCarouselNoHeroModule,
  singleTestimonial: SingleTestimonialModule,
  groupTestimonial: GroupTestimonialModule,
  linkGrid: LinkGridModule,
  featuredContent2Up: TwoUpModule,
  featuredContent3up: ThreeUpModule,
  relatedLinksModule: RelatedLinksModule,
  headerModule: HeaderModule,
  heroBodyModule: HeroBodyModule,
  heroBodyCarousel: HeroBodyCarousel,
  heroHeaderCarousel: Carousel,
  heroNarrativeCarousel: HeroNarrativeCarouselModule,
  heroNarrativeQuoteSlide: HeroNarrativeQuoteModule,
  heroNarrativeTextSlide: HeroNarrativeTextModule,
  relatedStoriesList: RelatedStoriesListModule,
  videoModule: VideoModule,
  videoModuleLongBody: VideoModule,
  featuredContentModule: FeaturedContentModule,
  articleHeaderModule: ArticleHeaderModule,
  miniCarouselNarrative: MiniCarouselNarrativeModule,
  fullWidthVideoModule: FullWidthVideoModule,
  fullWidthCarouselModule: FullWidthCarouselModule,
};

const mergeRefs = (
  ...refs: Array<React.MutableRefObject<any> | Function | null>
) => {
  const filteredRefs: Array<React.MutableRefObject<any> | Function | null> =
    refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];
  return (inst: any) => {
    for (const elementRef of filteredRefs) {
      if (typeof elementRef === 'function') {
        elementRef(inst);
      } else if (elementRef) {
        elementRef.current = inst;
      }
    }
  };
};

export { ModuleCarousel };
