import { EntryQuery, EntryListQuery, PagedData } from '../types';
import { expand } from './utils';
import { getPreviewAPI, getSearchAPI, getAPI } from './client';
import * as contentful from 'contentful';

// Fetch a single entry by leveraging fetchEntryList and limiting the result to one item.
export async function fetchEntry<EntryType>(
  props: EntryQuery,
): Promise<EntryType | null> {
  const data = await fetchEntryList<EntryType>({
    ...props,
    limit: 1, // Limit the result to one entry
    skip: 0, // Start from the beginning
  });

  const entries = data.entries;

  if (!entries || entries.length === 0) {
    return null; // Return null if no entries are found
  }

  if (entries.length > 1) {
    // Log an error if more than one entry is fetched (shouldn't happen with limit=1)
    // eslint-disable-next-line no-console
    console.error(
      `${entries.length} items returned for ${props.contentType} query`,
    );
  }

  return entries[0]; // Return the first (and only) entry
}

// Fetch a list of entries with various filtering and query options.
export async function fetchEntryList<EntryType>({
  contentType,
  order,
  filters = {},
  limit = 10,
  skip = 0,
  filterNulls = true,
  select,
  query,
  locale,
  preview = false,
  linksToEntry,
}: EntryListQuery): Promise<PagedData<EntryType>> {
  // Construct the query parameters for fetching entries
  const params: { [key: string]: any } = {
    limit,
    skip,
    order,
    select,
    query,
    locale,
    links_to_entry: linksToEntry,
  };

  // Add filters to the query parameters
  for (const [key, val] of Object.entries(filters)) {
    if (val !== null) {
      if (key.startsWith('sys')) {
        params[key] = val; // System-level filters
      } else {
        params[`fields.${key}`] = val; // Field-specific filters
      }
    }
  }

  // Fetch the entries using the API
  return apiFetchEntries<EntryType>(contentType, params, filterNulls, preview);
}

// Type for API fetch parameters
type ApiFetchEntriesParams = { [key: string]: any };

// Internal function to fetch entries from the Contentful API
async function apiFetchEntries<EntryType>(
  contentType: string | string[] = '',
  params: ApiFetchEntriesParams = {},
  filterNulls = true,
  preview = false,
): Promise<PagedData<EntryType>> {
  // Add default parameters to fetch entries
  const fetchParams: ApiFetchEntriesParams = { include: 4, ...params };

  // Add content type-specific filters
  if (contentType) {
    if (Array.isArray(contentType)) {
      fetchParams['sys.contentType.sys.id[in]'] = contentType.join(',');
    } else {
      fetchParams.content_type = contentType;
    }
  }

  // Determine the appropriate API (preview, search, or default)
  let api;
  if (preview) {
    api = await getPreviewAPI();
  } else if (params.query) {
    api = await getSearchAPI();
  } else {
    api = await getAPI();
  }

  // Fetch entries from the API
  // @ts-ignore
  const entries = await api.getEntries<EntryType>(fetchParams);

  // Filter out null entries if required
  if (filterNulls) {
    entries.items = entries.items.filter(
      // @ts-ignore
      (e: contentful.Entry<EntryType>) =>
        e !== null && Boolean(e.sys.contentType),
    );
  }

  // Handle circular references safely and parse entries
  // @ts-ignore
  const circularJsonSafeEntries = entries.stringifySafe();
  const parsedEntries = JSON.parse(circularJsonSafeEntries);

  // Expand entry data for easier consumption
  const items = parsedEntries.items.map(
    (entry: any) =>
      expand(
        entry.sys.id,
        entry.sys.contentType?.sys.id || '',
        entry.fields,
      ) as EntryType,
  );

  // Return the paged data
  return {
    limit: entries.limit,
    skip: entries.skip,
    total: entries.total,
    entries: items,
    totalUnfiltered: entries.total,
  } as PagedData<EntryType>;
}

export async function fetchRawPreviewData(id: string, locale: string) {
  const api = getPreviewAPI();
  // @ts-ignore
  const entries = await api.getEntries<EntryType>({
    ['sys.id']: id,
    locale,
    include: 4,
    limit: 1,
    skip: 0,
  });
  // filter nulls
  // Filter out null entries if required

  entries.items = entries.items.filter(
    // @ts-ignore
    (e: contentful.Entry<EntryType>) =>
      e !== null && Boolean(e.sys.contentType),
  );

  // Handle circular references safely and parse entries
  // @ts-ignore
  const circularJsonSafeEntries = entries.stringifySafe();
  const parsedEntries = JSON.parse(circularJsonSafeEntries);
  return parsedEntries;
}
