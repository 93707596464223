import { ContentfulAsset } from '../types';

const isContentful = (type: 'Entry' | 'Asset' | 'Link', v: any) =>
  v?.sys?.type === type;

/*
 * Formats a Contentful Entry of the form:
 *  {
 *    sys: { id: 'abc', contentType: { sys: { id: '123' } }, ... }
 *    fields: { some: 'value',  nested: { sys: { ... }, fields: { ... } }
 *  }
 * to a flatter structure with linked Entries/Assets also formatted
 *  {
 *    id: 'abc',
 *    contentType: '123',
 *    some: 'value',
 *    nested: { id: ..., contentType: ..., ... }
 *  }
 *
 * Assumptions:
 *  - the contentful client has been configured with `resolveLinks: true`
 *    - this ensures that valid linked Entries are resolved to their values
 *  - the entry has been fetched with `#getEntries` and processed with `#stringifySafe`
 *    - this ensures that circular entries are shown as Links rather than being resolved, avoiding infinite loops
 */
export function expand(id: string, ctype: string, fields: any) {
  if (!fields) {
    return fields;
  }

  fields.id = id;
  fields.contentType = ctype;

  for (const [key, val] of Object.entries(fields) as [string, any][]) {
    if (val === null || val === undefined) {
      continue;
    } else if (Array.isArray(val)) {
      fields[key] = val.map(v =>
        typeof v === 'object' && v.sys && v.fields && v.sys?.contentType?.sys
          ? expand(v.sys.id, v.sys.contentType.sys.id, v.fields)
          : v,
      );
    } else if (isContentful('Entry', val)) {
      fields[key] = expand(val.sys.id, val.sys.contentType.sys.id, val.fields);
    } else if (isContentful('Asset', val)) {
      const asset = val.fields as ContentfulAsset;
      fields[key] = asset;
    }
  }

  // make arrays of objects for field keys of the form:
  // foo__1__bar, foo__1__baz, foo__2__bar, foo__2__baz, etc.
  for (const [key, val] of Object.entries(fields)) {
    const [arrKey, indexKey, propKey] = key.split('__');
    if (arrKey && indexKey && propKey) {
      const i = parseInt(indexKey, 10) - 1;
      const arr = fields[arrKey] || [];
      arr[i] = arr[i] || {};
      arr[i][propKey] = val;
      fields[arrKey] = arr;
      delete fields[key];
    }
  }

  return fields;
}
