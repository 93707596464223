import memoize from 'lodash/memoize';
import * as contentful from 'contentful';

export const getPreviewAPI = () => {
  return contentful.createClient({
    host: process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
    accessToken: process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN!,
    retryOnError: false,
  });
};

export const getAPI = memoize(() => {
  return contentful.createClient({
    host: process.env.REACT_APP_CONTENTFUL_HOST,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
    retryOnError: false,
  });
});

export const getSearchAPI = memoize(() => {
  return contentful.createClient({
    host: process.env.REACT_APP_CONTENTFUL_HOST,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
    accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
    retryOnError: true,
    timeout: 10000,
  }).withoutLinkResolution;
});
