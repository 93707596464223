// ignore-string-externalization

import React from 'react';
import {
  makeContentfulAsset,
  ContentfulImage,
  lightCaptionStyle,
} from '../Contentful';
import { isContentfulImage } from '../utils';
import { DefaultRenderer } from './DefaultRenderer';
import { ImageAspectBox16x9 } from '@spotify-internal/frodor-modules';
import { renderToString } from 'react-dom/server';
import { maxWidth } from '../Module/ArticleModule/styleConstants';
import qs from 'query-string';
import styled from 'styled-components';
import { cssSpacing, headlineLarge } from '@spotify-internal/encore-web';

const dropCapStyle = `
  padding-left: ${cssSpacing('tighter-2')};
  padding-bottom: 0px;
  padding-right: ${cssSpacing('tighter-2')};
  float: left;
  position: relative;
  top: ${cssSpacing('tighter-2')};
  margin-bottom: ${cssSpacing('tighter-2')};
  margin-left: ${cssSpacing('tighter-2')};
  margin-right: ${cssSpacing('tighter-2')};
  font-size: ${headlineLarge.fontSize};
  font-weight: ${headlineLarge.fontWeight};
  font-family: ${headlineLarge.fontFamily};
`;

const DropCap = styled.p`
  max-width: ${maxWidth};
  margin: auto;
  &:first-letter {
    ${dropCapStyle}
  }
`;

export class ArticleRenderer extends DefaultRenderer {
  pNum = 0;
  initialDropcap = false;

  image(href: string, title: string, text: string) {
    const fullWidth = (text || '').indexOf('full-width') > -1;

    // contentful image: display as natural-width responsive asset
    const isContentfulImg = isContentfulImage(href);

    if (isContentfulImg && !fullWidth) {
      const bits = href.split('.');
      const contentType = `image/${bits[bits.length - 1].replace(
        'jpg',
        'jpeg',
      )}`;
      const asset = makeContentfulAsset({
        url: href,
        contentType,
        description: title || text,
      });

      // TODO: unhack caption styling
      return renderToString(
        <ContentfulImage
          asset={asset}
          caption={text}
          captionStyle={lightCaptionStyle}
        />,
      );
    }

    const txt = (text || '').replace('full-width', '');
    const image = <img src={href} alt={title} />;

    return `
      <figure>
        ${image}
        ${renderToString(
          <figcaption style={lightCaptionStyle}>{txt}</figcaption>,
        )}
      </figure>
    `;
  }

  paragraph(text: string) {
    if (text && !text.startsWith('[')) {
      this.pNum++;
    }

    let renderedText = text;

    // check if dropcap needed/requested
    if (
      text &&
      text.length > 1 &&
      (text.startsWith('^') ||
        (this.pNum === 1 && this.initialDropcap && /^[a-zA-Z\"]/.test(text)))
    ) {
      renderedText = renderToString(
        <DropCap
          dangerouslySetInnerHTML={{ __html: text.replace(/^\^/, '') }}
        />,
      );
    }

    return (super.paragraph as any)(renderYoutubeVideoParagraph(renderedText));
  }
}

/**
 * Renders a YouTube video iframe
 *
 * @example: [youtube-video nBu0rl5B70I]
 */
export const renderYoutubeVideoParagraph = (text: string) => {
  const isVideo = text.indexOf('[youtube-video') === 0;
  if (!isVideo) {
    return text;
  }

  const url = getVideoUrl(text);

  function videoId(videoUrl: string): string | null {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = videoUrl.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  const embedUrl: string = `https://www.youtube.com/embed/${videoId(
    url,
  )}?${qs.stringify({
    autoplay: 0,
    iv_load_policy: 3,
    playsinline: 1,
    rel: 0,
    showinfo: 0,
  })}`;

  const params = {} as any;
  const fullWidth = text.indexOf('[youtube-video-full') === 0;
  if (fullWidth) params.style = { maxWidth: 'unset' };

  const frame = (
    <iframe
      title="video"
      src={embedUrl}
      frameBorder="0"
      allowFullScreen
      allow="encrypted-media"
      {...params}
    />
  );

  if (url) {
    return renderToString(
      <ImageAspectBox16x9 data-testid="video-component" style={params.style}>
        {frame}
      </ImageAspectBox16x9>,
    );
  }

  return '';
};

function getVideoUrl(text: string) {
  try {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const [, , videoId] =
      /\[(youtube-video|youtube-video-full)\s+([\w-_]+)?\]/gi.exec(
        text,
      ) as any[];

    return `https://www.youtube.com/embed/${videoId}?iv_load_policy=3&playsinline=1&rel=0`;
  } catch (e) {
    // don't error out if we can't parse the YouTube ID
    console.error(e); // eslint-disable-line no-console
    return '';
  }
}
