// ignore-string-externalization

import React from 'react';
import { Module } from '../Module';
import { TemplateType } from '.';
import { getAuthorDateString } from '../utils';
import { ArticleModuleType } from '../Module/ArticleModule';
import { useDynamicNavBarColor } from './useDynamicNavBarColor';
import { ContentfulAsset } from '../Contentful';
import { ModuleType } from '../Module/types';
import {
  cssColorValue,
  cssSpacing,
  makeColorSetClass,
  screenSmMin,
  screenXsMax,
} from '@spotify-internal/encore-web';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${cssColorValue('backgroundBase')};
`;

const ModuleWrapper = styled.div<{ noPadding: boolean }>`
  ${({ noPadding }) =>
    !noPadding &&
    css`
      @media (max-width: ${screenXsMax}) {
        padding: ${cssSpacing('looser')} ${cssSpacing('base')};
      }

      @media (min-width: ${screenSmMin}) {
        padding: ${cssSpacing('looser')} ${cssSpacing('looser-4')};
      }
    `}
  background-color: ${cssColorValue('backgroundBase')};
  color: ${cssColorValue('textBase')};
`;

export type ArticleTemplateType = TemplateType & {
  headerModule?: ModuleType;
  introduction?: string;
  article?: string;
  video?: ModuleType;
  modules?: ModuleType[];
  relatedStories?: ModuleType;
  recirculationModule?: ModuleType;
  author?: string;
  releaseDate?: Date;
  initialDropcap?: boolean;
  thumbnailImage?: ContentfulAsset;
};

export const ArticleTemplate: React.FC<ArticleTemplateType> = template => {
  const {
    theme = 'Light',
    headerModule,
    introduction,
    article,
    video,
    modules = [],
    author,
    releaseDate = '',
    initialDropcap = false,
  } = template;

  const releaseDateParsed: Date = new Date(releaseDate);

  const releaseDateFormatted = releaseDateParsed.toLocaleDateString(undefined, {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });

  if (headerModule) {
    headerModule.releaseDate = releaseDateFormatted;
    headerModule.author = author;
  }

  let articleModule;
  if (article) {
    articleModule = {
      contentType: 'articleModule',
      introduction,
      author,
      releaseDate: releaseDateFormatted,
      body: article,
      initialDropcap,
    } as ArticleModuleType;
  }

  const isFullBleed = (mod: ModuleType) =>
    [
      'videoModule',
      'videoModuleLongBody',
      'relatedStoriesList',
      'articleHeaderModule',
      'articleModule',
      'featuredContentModule',
    ].includes(mod.contentType);

  const headerVideo = { ...video, isHeader: true };

  const allModules: any = [
    headerModule,
    articleModule,
    headerVideo,
    ...(modules || []),
  ].filter(m => Boolean(m?.contentType));

  useDynamicNavBarColor(allModules?.[0]);

  const renderModule = React.useCallback((mod: ModuleType) => {
    const modProps = { ...mod } as any;
    modProps.authorDateString = getAuthorDateString(
      modProps.author,
      modProps.releaseDate,
    );
    return (
      <div>
        <Module {...modProps} shouldAnimate={false} />
      </div>
    );
  }, []);

  const getModuleColorSetClass = (mod: ModuleType) => {
    // modules with set theme/themenames
    if (mod.themeName || mod.theme) {
      return mod.themeName === 'blackOnWhite' || mod.theme === 'blackOnWhite'
        ? makeColorSetClass('base')
        : makeColorSetClass('invertedDark');
    }
    // default use page template theme
    return theme === 'Light'
      ? makeColorSetClass('base')
      : makeColorSetClass('invertedDark');
  };
  return (
    <Wrapper
      data-testid={`landing-${template.urlSlug || 'unknown'}`}
      className={
        theme === 'Light'
          ? makeColorSetClass('base')
          : makeColorSetClass('invertedDark')
      }
    >
      {allModules.map((mod: ModuleType, idx: number) => (
        <div className={getModuleColorSetClass(mod)} key={`${mod.id}-${idx}`}>
          <ModuleWrapper noPadding={isFullBleed(mod)}>
            {renderModule(mod)}
          </ModuleWrapper>
        </div>
      ))}
    </Wrapper>
  );
};
