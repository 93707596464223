// ignore-string-externalization

import styled from 'styled-components';
import { Text, screenMdMin } from '@spotify-internal/encore-web';
import {
  AnimationContextType,
  DELAYS,
  fadeInAnimation,
  withAnimationContext,
} from '@spotify-internal/frodor-modules';

export const Headline = withAnimationContext(styled(Text).attrs({
  forwardedAs: 'h1',
  paddingBottom: 'looser-2',
  variant: 'titleLarge',
})<{ centered?: boolean; animationContext: AnimationContextType }>`
  ${fadeInAnimation(DELAYS.SECOND)};
  @media (min-width: ${screenMdMin}) {
    text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  }
`);
