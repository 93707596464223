// ignore-string-externalization

import React from 'react';
import styled from 'styled-components';
import {
  screenSmMax,
  screenSmMin,
  screenXsMax,
  cssSpacing,
} from '@spotify-internal/encore-web';
import { RelatedLink } from './RelatedLink';
import { RelatedLinkType } from './RelatedLink/RelatedLinkType';
import { ModuleType } from '../types';
import { MAX_CONTENT_WIDTH, MAX_CONTENT_SCREEN_WIDTH } from '../../constants';

const RelatedLinks = styled.div`
  display: flex;

  .relatedLink1 {
    padding-left: 50px;
    background-color: ${({ theme }) => theme.box.tileOneBackground};
    color: ${({ theme }) => theme.box.text};
    width: 50%;
    @media (max-width: ${screenSmMax}) {
      width: 100%;
    }

    @media (max-width: ${screenXsMax}) {
      padding: ${cssSpacing('tighter-4')} ${cssSpacing('tighter')};
    }

    @media (min-width: ${screenSmMin}) {
      padding: ${cssSpacing('looser-4')} ${cssSpacing('looser-4')};
    }
    @media (min-width: ${MAX_CONTENT_WIDTH}) {
      padding-left: ${cssSpacing('looser-4')};
    }
    @media (min-width: ${MAX_CONTENT_SCREEN_WIDTH}) {
      padding-left: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
      padding-right: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
    }
  }

  .relatedLink2 {
    background-color: ${({ theme }) => theme.box.tileTwoBackground};
    color: ${({ theme }) => theme.box.text};
    width: 50%;
    @media (max-width: ${screenSmMax}) {
      width: 100%;
    }
    @media (max-width: ${screenXsMax}) {
      padding: ${cssSpacing('tighter-4')} ${cssSpacing('tighter')};
    }

    @media (min-width: ${screenSmMin}) {
      padding: ${cssSpacing('looser-4')} ${cssSpacing('looser-4')};
    }
    @media (min-width: ${MAX_CONTENT_WIDTH}) {
      padding-right: ${cssSpacing('looser-4')};
    }
    @media (min-width: ${MAX_CONTENT_SCREEN_WIDTH}) {
      padding-left: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
      padding-right: calc((100% - ${MAX_CONTENT_WIDTH}) / 2);
    }
  }

  @media (max-width: ${screenSmMax}) {
    flex-direction: column;
    width: 100%;
  }
`;

type RelatedLinksModuleType = ModuleType & {
  relatedLink1: RelatedLinkType;
  relatedLink2: RelatedLinkType;
};

export const RelatedLinksModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  RelatedLinksModuleType
>(
  (
    {
      relatedLink1 = {
        headline: '',
        subtext: '',
        description: '',
        linkText: '',
        link: '',
      },
      relatedLink2 = {
        headline: '',
        subtext: '',
        description: '',
        linkText: '',
        link: '',
      },
    },
    ref,
  ) => {
    return (
      <RelatedLinks>
        <div className="relatedLink1">
          <RelatedLink ref={ref} relatedLink={relatedLink1} />
        </div>
        <div className="relatedLink2">
          <RelatedLink ref={ref} relatedLink={relatedLink2} />
        </div>
      </RelatedLinks>
    );
  },
);
