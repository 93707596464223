// ignore-string-externalization

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  cssSpacing,
  screenXsMax,
  screenSmMax,
  screenSmMin,
  screenMdMax,
  titleMedium,
} from '@spotify-internal/encore-web';

import { ContentGrid } from '../../ContentGrid';
import { ContentfulImage, ContentfulAsset } from '../../Contentful';
import { Headline, Supertext, ImageAspectBox16x9 } from '../../ui';
import { ModuleType } from '../types';
import { transformScaleUp } from '../../utils';
import { hexToRgba } from '../../../../features/src/color-conversion';
import { MODULE_THEMES } from '../../Theme';
import { itemToSearchHit } from '../../Search';
import type { SearchHit } from '../../Search/types';
import { useLocale } from '@spotify-internal/i18n-core';
import { Grid } from '@spotify-internal/frodor-modules';
import { InteractiveElement } from '../../../utils/InteractiveElement';

const FeaturedContent = styled.div<{ color: string }>`
  & > div > div,
  ul {
    padding-bottom: 0;
  }

  & > div > div > ul > li {
    margin-bottom: ${cssSpacing('looser')};
  }

  & > div:first-child > div > p {
    ${titleMedium}
    letter-spacing: -1.28px;
    text-transform: none;
    padding-top: ${cssSpacing('tighter-2')};
  }
  .hero {
    color: ${({ color }) => color};
    text-decoration: none;
    @media (max-width: ${screenSmMax}) {
      & h1 {
        line-height: 36px;
      }
    }

    &&:hover:not(:disabled),
    &&:focus:not(:disabled) {
      color: ${({ color }) => hexToRgba(color, 0.75)};
    }
  }
`;

const Hero = styled(Grid)<{ background: string; color: string }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: ${cssSpacing('looser-4')};
  padding-bottom: ${cssSpacing('looser-4')};
  color: ${({ color }) => color};
  background-color: ${({ background }) => background};

  &:hover {
    p,
    h1 {
      color: ${props => hexToRgba(props.color, 0.75)};
    }
    img {
      ${transformScaleUp};
      transition: transform 0.3s ease-in-out;
    }
  }

  @media (max-width: ${screenXsMax}) {
    flex-direction: column;
  }
`;

const ImageContainer = styled(ImageAspectBox16x9)`
  height: 100%;
  width: 120%;
  order: 3;
  overflow: hidden;

  @media (max-width: ${screenXsMax}) {
    width: 100%;
    order: 2;
  }
`;

const TextContainer = styled.div`
  order: 2;
  padding-right: ${cssSpacing('looser-4')};
  align-self: center;
  height: 100%;
  width: 80%;

  & h1 {
    padding-bottom: 0;
  }

  @media (max-width: ${screenXsMax}) {
    padding-top: ${cssSpacing('base')};
    width: 100%;
    order: 3;
  }
`;

const StyledHeadline = styled(Headline)`
  @media (min-width: ${screenSmMin}) and (max-width: ${screenMdMax}) {
    padding-bottom: ${cssSpacing('base')};
  }
`;

const StyledSupertext = styled(Supertext)`
  padding-bottom: ${cssSpacing('base')};
`;

const TRACK_CAT = 'module-featuredContent';

type FeaturedContentModuleType = {
  color?: string;
  background?: string;
  caption?: string;
  headline?: string;
  image?: ContentfulAsset;
  items?: Array<ModuleType>;
  gridSupertext?: string;
  heroSupertext?: string;
  theme?: string;
  articleLink?: ModuleType;
};

export const FeaturedContentModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  FeaturedContentModuleType
>(
  (
    {
      color = '',
      background = '',
      caption = '',
      items = [],
      gridSupertext = '',
      heroSupertext = '',
      theme = '',
      articleLink,
    },
    ref,
  ) => {
    const locale = useLocale();
    const hero = itemToSearchHit(articleLink, locale) as NonNullable<SearchHit>;

    const validatedItems = items.filter(i => i?.contentType, true).slice(0, 3);
    return (
      <FeaturedContent color={color}>
        <ThemeProvider theme={MODULE_THEMES[theme]}>
          <ContentGrid
            ref={ref}
            trackCategory={TRACK_CAT}
            data-testid={`featuredContent-module-${'id'}`}
            items={validatedItems}
            supertext={gridSupertext}
          />
        </ThemeProvider>
        {hero && (
          <InteractiveElement
            className="hero"
            href={hero.url}
            trackingCategory={TRACK_CAT}
            trackingLabel={`CTA Link - ${hero.title}`}
          >
            <Hero color={color} background={background}>
              <TextContainer>
                <StyledSupertext>{heroSupertext}</StyledSupertext>
                {hero.title && <StyledHeadline>{hero.title}</StyledHeadline>}
              </TextContainer>
              <ImageContainer>
                {hero.image && (
                  <ContentfulImage asset={hero.image} caption={caption} />
                )}
              </ImageContainer>
            </Hero>
          </InteractiveElement>
        )}
      </FeaturedContent>
    );
  },
);
