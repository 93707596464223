import React from 'react';
import styled from 'styled-components';
import {
  cssSpacing,
  IconChevronLeft,
  IconChevronRight,
  cssColorValue,
  screenSmMax,
  ButtonTertiary,
} from '@spotify-internal/encore-web';
import {
  DELAYS,
  fadeInAnimation,
  Grid,
  withAnimationContext,
} from '@spotify-internal/frodor-modules';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ModuleType } from '../types';
import { ContentfulAsset, ContentfulImage } from '../../Contentful';
import {
  ImageAspectBox1x1,
  HeadlineWithSupertextComponent,
  FeaturedContentHeadline,
} from '../../ui';
import { formatText } from '../../utils';
import { useT } from '@spotify-internal/i18n-core';
import { InteractiveElement } from '../../../utils/InteractiveElement';

const MiniCarouselNoHero = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.box.background};
  color: ${({ theme }) => theme.box.text};
  padding-top: ${cssSpacing('looser')};
  padding-bottom: ${cssSpacing('looser')};
`;

// TODO: find a better way to reorder than using separated styled components
const HeadlineWithSupertextDesktop = styled.div`
  @media (max-width: ${screenSmMax}) {
    display: none;
  }
`;

const HeadlineWithSupertextMobile = styled.div`
  display: none;
  @media (max-width: ${screenSmMax}) {
    display: initial;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: ${cssSpacing('looser-4')};
  padding-bottom: ${cssSpacing('base')};

  @media (max-width: ${screenSmMax}) {
    padding: ${cssSpacing('base')} 0px;
    flex-direction: column;
  }
`;

const Left = styled.div.attrs({
  variant: 'ballad',
})`
  width: 45%;
  margin-right: 180px;

  @media (max-width: ${screenSmMax}) {
    padding-right: 0px;
    width: 100%;
  }
`;

export const Arrow = styled(ButtonTertiary)`
  svg {
    padding: ${cssSpacing('tighter-4')};
    opacity: 0;
  }

  &:focus:not(:disabled) {
    svg {
      opacity: 1;
      border-bottom: 0;
    }
  }

  @media (max-width: ${screenSmMax}) {
    padding: 0 0;
    svg {
      opacity: 1;
    }
  }
`;

const CarouselContainer = styled.div`
  direction: ltr;

  width: 35%;
  margin-right: 75px;
  align-items: center;
  display: flex;

  @media (max-width: ${screenSmMax}) {
    padding-left: 0px;
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: ${screenSmMax}) {
    .alice-carousel {
      width: 80%;
    }
  }

  .alice-carousel__dots {
    margin: 0px;
    padding-top: ${cssSpacing('tighter-2')};
    height: ${cssSpacing('base')};
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .alice-carousel__dots-item {
    width: ${cssSpacing('tighter-2')};
    height: ${cssSpacing('tighter-2')};
    border-radius: 50%;
    border: none;
    background-color: ${cssColorValue('backgroundPress')};
    margin-left: ${cssSpacing('tighter-4')};
    margin-right: ${cssSpacing('tighter-4')};
    padding: 0px;
  }

  .alice-carousel__dots-item.__active {
    background-color: ${({ theme }) => theme.box.text};
  }
  @media (min-width: ${screenSmMax}) {
    &:hover {
      ${Arrow} svg {
        opacity: 1;
        color: ${({ theme }) => theme.box.text};
      }
    }
  }
`;

const Body = withAnimationContext(styled.div`
  ${fadeInAnimation(DELAYS.THIRD)};
`);

const ImageContainer = styled(ImageAspectBox1x1)``;

export type MiniCarouselNoHeroModuleType = ModuleType & {
  title?: string;
  supertext?: string;
  headline?: string;
  body?: string;
  image?: ContentfulAsset;
  caption?: string;
  carouselSlides?: any[];
  contentType: string;
};

export const MiniCarouselNoHeroModule = React.forwardRef<
  React.MutableRefObject<ModuleType>,
  MiniCarouselNoHeroModuleType
>(
  (
    {
      title = '',
      headline = '',
      supertext = '',
      body = '',
      image,
      caption = '',
      carouselSlides = [],
      contentType = '',
    },
    moduleRef,
  ) => {
    const t = useT();
    const slides = carouselSlides
      .filter(Boolean)
      .map(asset => asset.fields || asset);
    const fchProps = {
      supertext,
      headline,
      image,
      useFeaturedContentHeadline: false,
      caption,
    };

    const ref = React.useRef<AliceCarousel>(null);

    const handleClickOnPrevButton = () => {
      const { current } = ref;

      if (!current) {
        return;
      }

      const { slidePrev } = current;

      slidePrev();
    };

    const handleClickOnNextButton = () => {
      const { current } = ref;

      if (!current) {
        return;
      }

      const { slideNext } = current;

      slideNext();
    };

    return (
      <MiniCarouselNoHero>
        <HeadlineWithSupertextMobile>
          <HeadlineWithSupertextComponent
            supertext={supertext}
            headline={headline}
          />
        </HeadlineWithSupertextMobile>
        {image && <FeaturedContentHeadline {...fchProps} />}
        <Bottom ref={moduleRef as React.RefObject<HTMLDivElement>}>
          <Left>
            <HeadlineWithSupertextDesktop>
              <HeadlineWithSupertextComponent
                supertext={supertext}
                headline={headline}
              />
            </HeadlineWithSupertextDesktop>
            <Body dangerouslySetInnerHTML={{ __html: formatText(body) }} />
          </Left>
          <CarouselContainer>
            <InteractiveElement
              onClick={handleClickOnPrevButton}
              customComponent={Arrow}
              aria-label={t('FRODOR_6b0335', 'Go to previous slide', '')}
              iconOnly={() => <IconChevronLeft size="large" />}
              trackingLabel="Arrow"
              trackingCategory={`Frodor - module-${contentType}`}
            />

            <AliceCarousel
              items={slides.map((carouselImage, index) => (
                <ImageContainer key={index}>
                  {carouselImage && <ContentfulImage asset={carouselImage} />}
                </ImageContainer>
              ))}
              autoPlayInterval={4000}
              autoPlayDirection="ltr"
              autoPlay
              fadeOutAnimation
              mouseTrackingEnabled
              buttonsDisabled
              ref={ref}
              playButtonEnabled={false}
              disableAutoPlayOnAction
              duration={1000}
            />
            <InteractiveElement
              onClick={handleClickOnNextButton}
              customComponent={Arrow}
              aria-label={t('FRODOR_6b0335', 'Go to previous slide', '')}
              iconOnly={() => <IconChevronRight size="large" />}
              trackingLabel="Arrow"
              trackingCategory={`Frodor - module-${contentType}`}
            />
          </CarouselContainer>
        </Bottom>
      </MiniCarouselNoHero>
    );
  },
);
