import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import {
  Text,
  cssColorValue,
  ButtonPrimary,
  cssSpacing,
} from '@spotify-internal/encore-web';
import { useTracking } from '../Tracking';
import { defaultTheme } from '../Theme';
import { PageHead } from './PageHead';
import { useT } from '@spotify-internal/i18n-core';
import { InteractiveElement } from '../../utils/InteractiveElement';

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${cssColorValue('essentialAnnouncement')};
  padding: 120px ${cssSpacing('looser')};
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: auto;
  p {
    margin-top: ${cssSpacing('looser-4')};
    margin-bottom: ${cssSpacing('tighter')};
  }
`;

export const NotFound: React.FC = () => {
  const t = useT();
  const { sendEvent } = useTracking();

  React.useEffect(() => {
    sendEvent({
      eventCategory: `Frodor - Not Found`,
      eventAction: 'view',
      eventLabel: 'Not Found',
    });
  }, []);

  return (
    <ThemeProvider theme={defaultTheme} data-testid="not-found">
      <PageHead title={t('FRODOR_a9cd45', 'Not found', '')} />

      <Layout>
        <Content>
          <Text as="h1" paddingBottom="0.67em" variant="headlineMedium">
            {t(
              'FRODOR_8ecf25',
              'We couldn’t find that page.',
              "Couldn't means could not",
            )}
          </Text>

          <Text
            variant="bodyMediumBold"
            style={{
              display: 'block',
              marginBlockEnd: cssSpacing('looser'),
            }}
          >
            {t(
              'FRODOR_1660a4',
              'The link may be broken or we’ve taken the page down.',
              'Link here refers to a web link',
            )}
          </Text>
          <InteractiveElement
            customComponent={ButtonPrimary}
            onClick={() => {
              window.location.reload();
            }}
            colorSet="invertedDark"
            trackingLabel="homepage"
            trackingCategory="Frodor - Page not found"
          >
            {t('FRODOR_998bb6', 'Our Homepage', '')}
          </InteractiveElement>
        </Content>
      </Layout>
    </ThemeProvider>
  );
};
